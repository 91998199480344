import { useSelector } from 'react-redux';
import './index.scss'
import * as PR from "../../prime-modules/index";
import HeaderMenu from '../../components/layout/HeaderMenu';
import { Form, Formik } from 'formik';
import Input from '../../components/form/Input';
import { contactFormValidation } from '../../utils/schema';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { contactPostApi } from '../../services/api';
import { PARTNER_PROGRAM } from '../../services/interface';

const Partner = () =>{
    const {branding, labels} = useSelector(state=>state.language?.activelang);
    const toast = useRef();
    const formikRef = useRef();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { mutate } = useMutation( contactPostApi, {
        onSuccess:()=>{
            setIsSubmitted(true);;
        },
        onError: () =>{
            toast.current.show({severity: "error", summary: 'Error',detail: 'Error'});
        }
    });

    const submitHandler = ({email}) => {
        mutate({...PARTNER_PROGRAM, firstName:email.split('@')?.[0],  email});
    }
    return (
        <>
            <div className='main-section'>
                <PR.Toast ref={toast} />
                <HeaderMenu branding={branding} />
            </div>
            <div className="getintouch-section">
                <div className='container'>
                    <div className='grid custom-margin'>
                        <div className="col-12 text-center">
                            <h2 style={{margin:'0 -25px'}}>{branding?.BRAND0054} <span>{branding?.BRAND0055}</span></h2>
                            <Formik innerRef={formikRef} validationSchema={contactFormValidation} initialValues={{email:''}} onSubmit={submitHandler} >
                                <Form autoComplete="off" className="form-block" >
                                    <>
                                     <h3>{branding?.BRAND0090}</h3>
                                        <Input
                                            name="email"
                                            id="email"
                                            aria-describedby="email"
                                            placeholder={branding?.BRAND0052}
                                            disabled={isSubmitted}
                                        />
                                        <PR.Button
                                            icon={isSubmitted ? "pi pi-check-circle" : ""}
                                            label={isSubmitted ? labels?.LBL0311 : branding?.BRAND0056}
                                            type={isSubmitted ? "button" : "submit"}
                                            className={isSubmitted ? "success-button" : "goto-button"}
                                            iconPos="left"
                                        />
                                    </>
                                </Form>
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Partner