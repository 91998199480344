import { createSlice } from "@reduxjs/toolkit";
import assets from '../assets/data/assets.json'

const languageSlice = createSlice({
    name:'language',
    initialState:{activelang:assets},
    reducers:{
        setLanguageIntial(state,actions){
            const{k,v} =actions.payload;
            state.activelang = {...state.activelang, ...v};
            state[k] = {...state[k], ...v};
        },
        setLanguageStore(state,actions){
            
            state.activelang = state[actions.payload.k];
        }
    }
})

export const {setLanguageStore,setLanguageIntial} = languageSlice.actions;
export default languageSlice.reducer;