import React, { useEffect, useRef, useState } from 'react';
import "./Home.scss";
import * as PR from "../../prime-modules/index";
import { video, logoIcon, caseStudy1, caseStudy2, caseStudy3, caseStudy4, caseStudy5, affiliateProgram } from "../../assets/images";
import { videoOgg, videoMp4, videoWebm } from "../../assets/videos";
import Layout from "../../components/layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import FadeText from "../../components/FadeText";
import VerticalCarousel from "../../components/VerticalCarousel";
import { useSelector } from 'react-redux';
import VerticalSlider from '../../components/VerticalSlider';

const Home = () => {
    const shopDomainName = process.env.REACT_APP_SHOP_URL;
    const sectionRef = useRef(null);
    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);
    const section5Ref = useRef(null);
    const section6Ref = useRef(null);
    const [isSection1InView, setIsSection1InView] = useState(false);
    const [isSection2InView, setIsSection2InView] = useState(false);
    const [hasAnimatedSection2, setHasAnimatedSection2] = useState(false);
    const navigate = useNavigate();
    const {branding} = useSelector(state=>state.language?.activelang);
    const videoRef = useRef(null);
    const [activeSection, setActiveSection] = useState(0);
    const [showDots, setShowDots] = useState(false);
    const [footerTouched, setFooterTouched] = useState(false);
    const footerRef = useRef(null);

    const texts = [
        branding?.BRAND0012,
        branding?.BRAND0013,
        branding?.BRAND0014
    ];

    const items = [
        { content: branding?.BRAND0026, bgColor: 'lightcoral' },
        { content: branding?.BRAND0027, bgColor: 'lightblue' },
        { content: branding?.BRAND0028, bgColor: 'lightgreen' },
        { content: branding?.BRAND0029, bgColor: 'lightpink' },
        { content: branding?.BRAND0030, bgColor: 'lightyellow' },
        { content: branding?.BRAND0031, bgColor: 'lightyellow' },
        { content: branding?.BRAND0032, bgColor: 'lightyellow' },
    ];

    const scrollToSection = (ref) => {
        window.scrollTo({
          top: ref.current.offsetTop,
          behavior: 'smooth',
        });
        if (videoRef.current) {
            videoRef.current.play(); 
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };

    const handleVideoPlay = () => {
        if (videoRef.current) {
            videoRef.current.removeAttribute('controls');
        }
    };

    const handleVideoClick = () => {
        if (videoRef.current) {
            videoRef.current.setAttribute('controls', 'controls');
        }
    };

    const handleDotClick = (sectionIndex) => {
        const sectionRefs = [section1Ref, section2Ref, section3Ref, section4Ref, section5Ref, section6Ref];
        const blockPosition = (sectionIndex === 0 || sectionIndex === 4) ? 'start' : 'center';
        sectionRefs[sectionIndex].current.scrollIntoView({
            behavior: 'smooth',
            block: blockPosition,
        });
    };

    useEffect(() => {
        const sectionRefs = [section1Ref, section2Ref, section3Ref, section4Ref, section5Ref, section6Ref];
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                const index = sectionRefs.findIndex(
                  (ref) => ref.current === entry.target
                );
                setActiveSection(index);
              }
            });
          },
          { threshold: 0.5 }
        );
    
        sectionRefs.forEach((ref) => {
          if (ref.current) {
            observer.observe(ref.current);
          }
        });
    
        return () => {
          sectionRefs.forEach((ref) => {
            if (ref.current) {
              observer.unobserve(ref.current);
            }
          });
        };
      }, []);

      useEffect(() => {
        // Observer for Section 1
        const observer1 = new IntersectionObserver(
            (entries) => {
            const entry = entries[0];
            setIsSection1InView(entry.isIntersecting);
            },
            { threshold: 0.5 }
        );

        // Observer for Section 2
        const observer2 = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting && !hasAnimatedSection2) {
                    setIsSection2InView(true);
                    setHasAnimatedSection2(true);
                }
            },
            { threshold: 0 }
        );

        if (section1Ref.current) {
            observer1.observe(section1Ref.current);
        }

        if (section2Ref.current) {
            observer2.observe(section2Ref.current);
        }

        return () => {
            if (section2Ref.current) {
                observer2.unobserve(section2Ref.current);
            }
        };
    }, [hasAnimatedSection2]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = window.innerHeight / 2;
            if (window.scrollY >= scrollThreshold) {
                setShowDots(true);
            } else {
                setShowDots(false);
            }
        };
    
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setFooterTouched(true);
                } else {
                    setFooterTouched(false);
                }
            },
            { threshold: 0.1 }
        );
    
        if (footerRef.current) {
            observer.observe(footerRef.current);
        }
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, []);

    return (
    <>
        <Layout footerRef={footerRef}>
            {/*** Header Section ***/}
            <section className="header-text-section">
                <div className="flex align-items-center justify-content-between">
                    <div className="header-text">
                        <h1>{branding?.BRAND0010}
                        <FadeText texts={texts} interval={3200} />
                         {branding?.BRAND0011}</h1>
                    </div>
                    <div className="learn-arrow" onClick={() => scrollToSection(sectionRef)}>
                        <i className="pi pi-angle-double-left"></i>
                        <span>{branding?.BRAND0050}</span>
                    </div>
                </div>
            </section>

            {showDots && (
              <div className={`scroll-container ${footerTouched ? 'dots-hide' : ''}`}>
                <div className="dots-container">
                    <div className="dot home" onClick={scrollToTop}>
                        <span className="tooltip">Home</span>
                    </div>
                    <div className="dot" onClick={() => scrollToSection(sectionRef)}>
                        <span className="tooltip">Learn how</span>
                    </div>
                    {['Market research', 'About data2go', 'Why work with us?', 'Case studies', 'Success stories', 'Become a Partner'].map((section, index) => (
                    <div
                        key={index}
                        className={`dot ${index === activeSection ? 'active' : ''}`}
                        onClick={() => handleDotClick(index)}
                    >
                        <span className="tooltip">{section}</span>
                    </div>
                    ))}
                </div>
            </div> )}

            {/*** Video Section ***/}
            <section className="video-section" ref={sectionRef} id="section-1">
                <div className="grid grid-nogutter">
                    <div className="col-12 text-center">
                        <div className="video-wrapper">
                            <video className='video' onPlay={handleVideoPlay} onClick={handleVideoClick} poster={video} ref={videoRef}>
                                <source src={videoMp4} type="video/mp4"/>
                                <source src={videoOgg} type="video/ogg"/>
                                <source src={videoWebm} type="video/webm" />
                            </video>
                        </div>
                    </div>
                </div>
                <div className="grid grid-nogutter">
                    <div className="col-12">
                        <div className="video-info">
                            <h2>{branding?.BRAND0015}</h2>
                            <h2>{branding?.BRAND0016}</h2>
                            <h2>{branding?.BRAND0017}</h2>
                            <h2>{branding?.BRAND0018}</h2>
                        </div>
                    </div>
                </div>
                <div className="grid grid-nogutter">
                    <div className="col-12 text-center">
                        <PR.Button onClick={navigate.bind(null,'/get-in-touch')} label={branding?.BRAND0019} className="getintouch-button" />
                    </div>
                </div>
            </section>

            {/*** eSIM Information Section ***/}
            <section ref={section1Ref} className={`esim-info-section ${isSection1InView ? 'start-animation' : ''}`} id="section-2">
                <div className="grid grid-nogutter">
                    <div className="col-12">
                        <div className='data2go-about-block'>
                            <h2>
                                <div className="typing-section">
                                    <div className="typing-container smooth-text">{branding?.BRAND0020} <span>{branding?.BRAND0021}</span> {branding?.BRAND0022}</div>
                                </div>
                            </h2>
                        </div>
                        <svg width="100%" height="94%" viewBox="0 0 1247 908" fill="none" xmlns="http://www.w3.org/2000/svg" className='bars-svg'>
                            <rect className="rect1" x="0" y="908" width="320" height="342" fill="#160033" />
                            <rect className="rect2" x="450" y="908" width="320" height="714" fill="#160033" />
                            <rect className="rect3" x="927" y="908" width="320" height="908" fill="#160033" />
                        </svg>
                    </div>
                </div>
            </section>

            {/*** white label info Section ***/}
            <section ref={section2Ref} className={`white-label-info-section ${isSection2InView ? 'start-animation' : ''}`} id="section-3">
                <div className="grid grid-nogutter">
                    <div className="col-12 text-center">
                        <div className='data2go-white-label'>
                            <PR.Image src={logoIcon} alt="data2go logo" className="logo-img" />
                            <h2>
                                <div className="typing-section">
                                    <div className="typing-container smooth-text">{branding?.BRAND0023} <br/>{branding?.BRAND0024}<br/> {branding?.BRAND0025}</div>
                                </div>
                            </h2>
                        </div>
                    </div>
                </div>
                <svg width="100%" height="100%" viewBox="0 0 1920 2080" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="path" id="path1" d="M1425.75 233.242C1535.61 296.667 1583.53 444.16 1571.6 632.085C1559.68 819.907 1487.96 1047.61 1359.21 1270.61C1230.46 1493.62 1069.12 1669.58 912.426 1773.81C755.643 1878.11 603.947 1910.35 494.091 1846.92C384.235 1783.5 336.307 1636.01 348.239 1448.08C360.164 1260.26 431.881 1032.56 560.633 809.554C689.385 586.549 850.72 410.59 1007.42 306.352C1164.2 202.056 1315.89 169.817 1425.75 233.242Z" stroke="url(#paint1)" strokeWidth={3} />
                    <path className="path" id="path2" d="M28.2613 1040.08C28.2613 913.23 132.029 797.977 300.743 714.347C469.364 630.763 702.416 579.023 959.92 579.023C1217.42 579.023 1450.48 630.764 1619.1 714.347C1787.81 797.977 1891.58 913.23 1891.58 1040.08C1891.58 1166.93 1787.81 1282.18 1619.1 1365.81C1450.48 1449.4 1217.42 1501.14 959.92 1501.14C702.416 1501.14 469.364 1449.4 300.743 1365.81C132.029 1282.18 28.2613 1166.93 28.2613 1040.08Z" stroke="url(#paint2)" strokeWidth={3} />
                    <path className="path" id="path3" d="M1618.7 1698.86C1529.01 1788.56 1374.13 1796.68 1195.7 1736.52C1017.37 1676.39 815.986 1548.18 633.903 1366.1C451.82 1184.02 323.614 982.636 263.483 804.301C203.32 625.867 211.441 470.996 301.138 381.299C390.835 291.602 545.706 283.481 724.14 343.645C902.475 403.775 1103.85 531.982 1285.94 714.065C1468.02 896.147 1596.23 1097.53 1656.36 1275.86C1716.52 1454.3 1708.4 1609.17 1618.7 1698.86Z" stroke="url(#paint3)" strokeWidth={3} />
                    
                    <circle r="14" fill="#386DF7" className='circle'>
                        <animateMotion dur="35s" repeatCount="indefinite">
                            <mpath href="#path1" />
                        </animateMotion>
                    </circle>
                    <circle r="14" fill="#F77D38" className='circle'>
                        <animateMotion dur="40s" repeatCount="indefinite">
                            <mpath href="#path2" />
                        </animateMotion>
                    </circle>
                    <circle r="14" fill="#BA38F7" className='circle'>
                        <animateMotion dur="45s" repeatCount="indefinite">
                            <mpath href="#path3" />
                        </animateMotion>
                    </circle>
                    
                    <defs>
                        <linearGradient id="paint1" x1="559.334" y1="808.804" x2="1360.51" y2="1271.36" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1145" />
                            <stop offset="1" stopColor="#252AAB" />
                        </linearGradient>
                        <linearGradient id="paint2" x1="959.92" y1="1502.64" x2="959.92" y2="577.523" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1145" />
                            <stop offset="1" stopColor="#252AAB" />
                        </linearGradient>
                        <linearGradient id="paint3" x1="1287" y1="713.004" x2="632.843" y2="1367.16" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0F1145" />
                            <stop offset="1" stopColor="#252AAB" />
                        </linearGradient>
                    </defs>
                </svg>
            </section>

            {/*** Why work with us Section ***/}
            <section ref={section3Ref}  className="why-work-with-us-section" id="section-4">
                <div className="grid align-items-center container">
                    <div className="col-12 md:col-7">
                        <h2>{branding?.BRAND0033} <br/> {branding?.BRAND0034}?</h2>
                    </div>
                    <div className="col-12 md:col-5">
                        <VerticalCarousel items={items} interval={1500} />
                    </div>
                </div>
                <div className="half-circle"></div>
            </section>

            {/*** Case study Section ***/}
            <section ref={section4Ref}  className="case-study-section" id="section-5">
                <div className="container">
                    <div className="col-12 text-center">
                        <h2>{branding?.BRAND0035}!</h2>
                    </div>
                    <div className="grid align-items-center text-center justify-content-center">
                        <div className="col-12 md:col-6 lg:col-4">
                            <div onClick={navigate.bind(null,'/request-case-study',{state:branding?.BRAND0036 + " " + branding?.BRAND0037})} className="box">
                                <PR.Image src={caseStudy1} alt="Case study" className="casestudy-img" />
                                <h3>{branding?.BRAND0036} <br/> {branding?.BRAND0037}</h3>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-4">
                            <div onClick={navigate.bind(null,'/request-case-study',{state:branding?.BRAND0038 + " " + branding?.BRAND0039})} className="box">
                                <PR.Image src={caseStudy2} alt="Case study" className="casestudy-img" />
                                <h3>{branding?.BRAND0038} <br/>{branding?.BRAND0039} </h3>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-4">
                            <div onClick={navigate.bind(null,'/request-case-study',{state:branding?.BRAND0040 + " " + branding?.BRAND0041})} className="box">
                                <PR.Image src={caseStudy3} alt="Case study" className="casestudy-img" />
                                <h3>{branding?.BRAND0040} <br/> {branding?.BRAND0041}</h3>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-4">
                            <div onClick={navigate.bind(null,'/request-case-study',{state:branding?.BRAND0042 + " " + branding?.BRAND0037})} className="box">
                                <PR.Image src={caseStudy4} alt="Case study" className="casestudy-img" />
                                <h3>{branding?.BRAND0042} <br/> {branding?.BRAND0037}</h3>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-4">
                            <div onClick={navigate.bind(null,'/request-case-study',{state:branding?.BRAND0043 + " " + branding?.BRAND0044})} className="box">
                                <PR.Image src={caseStudy5} alt="Case study" className="casestudy-img" />
                                <h3>{branding?.BRAND0043} <br/> {branding?.BRAND0044}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-nogutter">
                        <div className="col-12 text-center">
                            <PR.Button onClick={navigate.bind(null,'/request-case-study')} label={branding?.BRAND0045} className="request-button" />
                        </div>
                    </div>
                </div>
                <div className="half-circle"></div>
            </section>

             {/*** Success Story Section ***/}
             <section className="success-story-section" id="section-6">
                <div className="flex align-items-center container justify-content-between" ref={section5Ref}>
                    <div className="left">
                        <h2>{branding?.BRAND0046} <span>{branding?.BRAND0047}</span></h2>
                    </div>
                    <div className="right">
                        <Link to={shopDomainName} target="_blank" className="getintouch-button">{branding?.BRAND0001}</Link>
                    </div>
                </div>
                <div className='vertical-slider-section'>
                    <VerticalSlider/>
                </div>
            </section>

            {/*** Affiliate Section ***/}
            <section ref={section6Ref} className="affiliate-section" id="section-7">
                <Link to={'/partner'}>
                    <div className="affiliate-container">
                        <div className="grid">
                            <div className="col-12 lg:col-8">
                                <PR.Image src={affiliateProgram} alt="Affiliate Program"/>
                            </div>
                            <div className="col-12 lg:col-4 text-right right-block">
                                <Link to={'/partner'}>
                                    <div className="arrow-icon">
                                        <i className="pi pi-arrow-up-right"></i>
                                    </div>
                                </Link>
                                <h2>{branding?.BRAND0048} <br/> {branding?.BRAND0049}</h2>
                            </div>
                        </div>
                    </div>
                </Link>
            </section>
        </Layout>
    </>
  );
};

export default Home;